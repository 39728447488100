<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/20/solid'
import { useField } from 'vee-validate'
import { ref } from 'vue'

const props = defineProps<{
  name: string
  label: string
  description?: string
  multiple?: boolean
  accept?: string
  maxFileSize?: number
  rules?: any
  required?: boolean
}>()

const { value, errorMessage, handleChange, handleBlur, resetField } = useField(props.name)

const fileEl = ref()
const handleRemove = (idx?: number) => {
  const dt = new DataTransfer()
  if (Array.isArray(value.value) && typeof idx !== 'undefined') {
    value.value.splice(idx, 1)
    for (const file of value.value) {
      dt.items.add(file)
    }
  } else {
    resetField()
  }

  fileEl.value.files = dt.files
}
</script>
<template>
  <div>
    <label class="block text-sm font-medium leading-6 text-gray-900">
      <div>{{ label }}<span v-if="required" class="pl-1 text-kmw-blue">(Pflichtfeld)</span></div>
      <p v-if="description" class="mt-px text-sm leading-6 text-gray-600">
        {{ description }}
      </p></label
    >
    <div class="mt-2 flex items-start space-x-2">
      <label :for="name" class="button">Datei auswählen</label>
      <input
        ref="fileEl"
        :id="name"
        :name="name"
        type="file"
        :multiple="multiple"
        :accept="accept"
        @change="handleChange"
        @blur="handleBlur"
        class="sr-only"
      />
      <div v-if="value">
        <ul class="space-y-2">
          <template v-if="Array.isArray(value)">
            <li v-for="(file, idx) in value" class="flex items-center">
              <span>{{
                //@ts-ignore
                file.name
              }}</span>
              <button @click.prevent="handleRemove(idx)" class="ml-2">
                <XMarkIcon class="w-5 h-5"></XMarkIcon>
              </button>
            </li>
          </template>
          <template v-else>
            <li class="flex items-center">
              <span>{{
                //@ts-ignore
                value.name
              }}</span>
              <button @click.prevent="handleRemove()" class="ml-2">
                <XMarkIcon class="w-5 h-5"></XMarkIcon>
              </button>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <p v-if="errorMessage" class="block text-sm text-red-600" v-text="errorMessage" />
  </div>
</template>
