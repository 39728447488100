import { createApp } from 'vue'
import App from './App.vue'
import * as Sentry from '@sentry/vue'

import './assets/css/main.css'

const app = createApp(App)

if (import.meta.env.VITE_GLITCHTIP_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_GLITCHTIP_DSN
  })
}

app.mount('#app')
