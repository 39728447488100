<script setup lang="ts">
import { computed } from 'vue';
import FreiwilligenFormularComponent from './components/FreiwilligenFormular.ce.vue'
import OutsideApplicationPeriod from './components/OutsideApplicationPeriod.vue'

import { DateTime } from 'luxon';

const insideApplicationPeriod = computed(() => {
  var now = DateTime.now();
  return now >= DateTime.fromObject({day: 2, month: 5}) && now < DateTime.fromObject({day: 31, month: 10});
});

const isDevEnvironment = computed(() => {
  return import.meta.env.VITE_APP_HOSTING_ENVIRONMENT == 'microk8s';
});

</script>

<template>
  <div class="max-w-7xl mx-auto px-8 py-8">
    <div class="w-full max-w-[12rem]">
      <img src="/DieSternsinger_Kindermissionswerk-Logo-600x112.png" />
    </div>
  </div>
  <div class="py-12">
    <FreiwilligenFormularComponent v-if="insideApplicationPeriod || isDevEnvironment"></FreiwilligenFormularComponent>
    <OutsideApplicationPeriod v-else></OutsideApplicationPeriod>
  </div>
  <div class="bg-kmw-sky py-8">
      <div class="max-w-7xl mx-auto px-8">
        <div class="flex flex-row justify-between items-center">
            <div class="w-full max-w-[24rem]">
              <img src="/DieSternsinger_Kindermissionswerk-Logo-600x112.png" />
            </div>
            <div class="flex flex-col">
              <div>
                <a href="https://mein-eine-welt-jahr.de/impressum" class="hover:underline text-xl">Impressum</a>
              </div>
              <div>
                <a href="https://mein-eine-welt-jahr.de/datenschutz" class="hover:underline text-xl">Datenschutz</a>
              </div>
            </div>
        </div>
      </div>
  </div>
</template>
