<template>
    <div class="max-w-7xl mx-auto px-8">
        <h1 class="text-5xl pb-12">Bewerbung zur Zeit nicht möglich</h1>
        <div class="flex flex-col space-y-4">
            <p>
                Leider hast du diese Seite außerhalb des Bewerbungszeitraums aufgerufen. Bitte informiere dich auf unserer Website www.mein-eine-welt-jahr.de, wann die neue Bewerbungsphase startet.
            </p>
            <div>
                <a href="https://www.mein-eine-welt-jahr.de" class="button button--primary">Zur Website</a>
            </div>
        </div>
    </div>
</template>