<script setup lang="ts">
import Grid from './layout/Grid.vue'
import UIInput from './UI/Input.vue'
import UITextArea from './UI/TextArea.vue'
import UICheckboxGroup from './UI/CheckboxGroup.vue'
import UIRadioGroup from './UI/RadioGroup.vue'
import UIDatePicker from './UI/DatePicker.vue'
import UISelect from './UI/Select.vue'
import UIFileInput from './UI/FileInput.vue'
import Notification from './Notification.vue'

import {heimatdiozese} from '../options'

import {serialize} from 'object-to-formdata'
import {DateTime, Interval} from 'luxon'
import {FieldArray, Form} from 'vee-validate'
import {setLocale, object, string, date, array, mixed} from 'yup'
import {MinusIcon, PlusIcon} from '@heroicons/vue/20/solid'
import {onMounted, ref} from 'vue'

const n = Interval.fromDateTimes(
    DateTime.now().minus({years: 23}),
    DateTime.now().minus({years: 11})
)
    .splitBy({year: 1})
    .map((d) => d.start)
    .map((d) => {
      if (d) {
        return {label: d.year, value: d.year}
      }
      return
    })

setLocale({
  mixed: {
    default: 'Ungültige Eingabe.',
    required: 'Dies ist ein Pflichtfeld.'
  },
  string: {
    email: 'Bitte gib ein gültiges E-Mail Format ein.'
  },
  array: {
    min: 'Treffe mindestens eine Auswahl von ${min}.'
  }
})

function getLeaveDate(): DateTime {
  const leaveYear = DateTime.now().year + 1;
  const leaveDate = DateTime.fromObject({day: 1, month: 8, year: leaveYear}, {zone: 'Europe/Berlin'});

  return leaveDate;
}

function getLeaveDateValidationMessage(): string {
  const date = getLeaveDate();

  return `Du musst bei der Ausreise Anfang ${date.monthLong} ${date.year} zwischen 18 und 28 Jahren alt sein.`;
}

const phoneRegEx =
    /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d*)\)?)[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/
const schema = object({
  kontakt: object({
    vorname: string().required(),
    nachname: string().required(),
    geschlecht: string().required(),
    familienstand: string().required(),
    geburtsdatum: date()
        .typeError('Bitte ein gültiges Datum angegeben.')
        .required()
        .min(getLeaveDate().minus({years: 28}), getLeaveDateValidationMessage)
        .max(getLeaveDate().minus({years: 18}), getLeaveDateValidationMessage),
    geburtsort: string().required(),
    email: string()
        .email()
        .required()
        .matches(/^\S+@\S+\.\S+$/, 'Bitte gib ein gültiges E-Mail Format ein.'),
    mobil: string().required().matches(phoneRegEx, 'Bitte gib eine gültige Telefonnummer ein.'),
    festnetz: string().matches(phoneRegEx, 'Bitte gib eine gültige Telefonnummer ein.'),
    adresse: object({
      strasse: string().required(),
      postleitzahl: string()
          .matches(/^[0-9]+$/, 'Bitte gib eine gültige Postleitzahl an.')
          .min(4, 'Bitte gib eine gültige Postleitzahl an.')
          .max(5, 'Bitte gib eine gültige Postleitzahl an.')
          .required(),
      ort: string().required(),
      land: string().required()
    }),
    staatsangehoerigkeit: string().required(),
    staatsangehoerigkeit_nicht_deutsch: string().when('staatsangehoerigkeit', {
      is: (val: string) => val && val !== 'deutsch',
      then: (schema) => schema.required()
    }),
    konfession: string().required(),
    heimatdioezese: string().when('konfession', {
      is: (val: string) => val === 'katholisch',
      then: (schema) => schema.required()
    }),
    konfession_sonstige: string().when('konfession', {
      is: (val: string) => val === 'sonstige',
      then: (schema) => schema.required()
    })
  }),
  karriere: object({
    grundschule: object({
      name: string().required(),
      from: string().required(),
      to: string().required()
    }),
    weiterfuehrende_schule: array().of(
        object({
          name: string().required(),
          from: string().required(),
          to: string().required()
        })
    ),
    zurzeit_taetig_als: string().required()
  }),
  engagement: object({
    engagiert: object({
      status: string().required()
    }),
    arbeitserfahrung: object({
      status: string().required()
    }),
    auslandserfahrungen: object({
      status: string().required()
    })
  }),
  sprachen: object({
    englisch: string().required(),
    spanisch: string().required(),
    franzoesisch: string().required(),
    sonstiges: string()
  }),
  einsatzplan: object({
    interesse: string().required(),
    erlaeuterung: string().when('interesse', {
      is: (val: string) => val === 'ja',
      then: (schema) => schema.required()
    })
  }),
  aufmerksam: object({
    durch: array().required()
  }),
  uploads: object({
    bewerbungsfoto: mixed()
        .required()
        .test('type', 'Bitte lade eine Datei im JPEG oder PNG Format hoch.', (value: any) => {
          return value && ['image/png', 'image/jpeg'].includes(value.type)
        }),
    motivation: mixed()
        .required()
        .test('type', 'Bitte lade eine Datei im PDF Format hoch.', (value: any) => {
          return value && ['application/pdf'].includes(value.type)
        }),
    zeugnis: mixed()
        .required()
        .test('type', 'Bitte lade eine Datei im JPEG, PNG oder PDF Format hoch.', (value: any) => {
          return value && ['image/png', 'image/jpeg', 'application/pdf'].includes(value.type)
        }),
    nachweise: array()
        .of(
            mixed()
                .nullable()
                .test('type', 'Bitte lade Dateien im JPEG, PNG oder PDF Format hoch.', (values: any) => {
                  if (!values || !values.length) return true
                  return values.every((file: any) =>
                      ['image/png', 'image/jpeg', 'application/pdf'].includes(file.type)
                  )
                })
        )
        .max(5, 'Bitte hänge maximal 5 weitere Nachweise an.')
  }),
  bestaetigung: array()
      .of(string().oneOf(['Seminarteilnahme', 'Untersuchungen', 'Datenschutzerklärung']))
      .min(3, 'Bitte bestätige alle oben genannten Punkte.')
      .required()
})

const onInvalidSubmit = (props: any) => {
  const {errors} = props
  if (Object.keys(errors).length) {
    const firstErrorName = Object.keys(errors)[0]
    document.querySelector(`[name="${firstErrorName}"]`)?.scrollIntoView({block: 'center'})
  }
}

const validateMaxOrte = (places: any, max: number) => {
  const countOrteRecursive = (parent: any) => {
    if (!parent) return 0

    if (Array.isArray(parent)) {
      return parent.length
    }

    if (typeof parent === 'object') {
      let count = 0
      for (const place in parent) {
        if (Object.prototype.hasOwnProperty.call(parent, place)) {
          count += countOrteRecursive(parent[place])
        }
      }

      return count
    }

    return 1
  }

  let countOrte = 0
  Object.keys(places).forEach((key) => {
    if (places[key]) {
      countOrte += countOrteRecursive(places[key])
    }
  })

  return countOrte <= max
}

const einsatzplanError = ref<string>()
const loading = ref(false)
const notification = ref<{ show: boolean; type?: string; title?: string; body?: string }>({
  show: false
})
const onSubmit = async (values: any, props: any) => {
  const {resetForm} = props
  const {uploads, ...data} = values

  if (!validateMaxOrte(data.einsatzplan?.orte, 5)) {
    einsatzplanError.value = 'Beschränke deine Auswahl bitte auf 5 Einsatzstellen'
    document.querySelector(`[name="einsatzplan.orte"]`)?.scrollIntoView({block: 'center'})
    return
  }

  let formData = serialize({data: JSON.stringify(data)})

  if (uploads) {
    // append files
    Object.keys(uploads).forEach((key) => {
      const value = uploads[key]

      if (value) {
        if (Array.isArray(value)) {
          for (const file of value) {
            formData.append(file.name, file)
          }
        }
        if (value instanceof File) {
          formData.append(value.name, value)
        }
      }
    })
  }

  try {
    loading.value = true
    const res: any = await fetch(
        `${import.meta.env.VITE_API_BASE_URL || 'http://microk8s.kmw:30150'}/api/application`,
        {
          method: 'POST',
          body: formData
        }
    )

    const {status} = res

    if (status === 200 || status === 201) {
      window.onbeforeunload = null
      resetForm()
      notification.value = {show: true, type: 'success'}
      document.getElementById('notification')?.scrollIntoView({block: 'center'})
      initializeClicks()
    } else {
      notification.value = {
        show: true,
        type: 'error',
        title: 'Fehler',
        body:
            status === 415
                ? 'Eine oder mehrere Dateien deiner Anhänge konnten nicht verarbeitet werden. Prüfe bitte, ob du gültige Bild- und PDF-Anhänge mitschickst.'
                : 'Bei der Verarbeitung deiner Bewerbung ist ein Fehler aufgetreten. Bitte versuche es noch einmal.'
      }
      document.getElementById('notification')?.scrollIntoView({block: 'center'})
    }
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

const initializeClicks = () => {
  document.querySelectorAll('.initial-click').forEach((el: Node) => {
    //@ts-ignore
    el.click()
  })
}
onMounted(() => {
  // FieldArray need initialValues to show the first element
  // since we do not use initialValues, click the FieldArray.push() on mounted
  initializeClicks()

  window.onbeforeunload = (e) => {
    return false
  }
})
</script>

<template>
  <div class="max-w-7xl mx-auto px-8">
    <h1 class="text-5xl pb-12">Bewerbung</h1>
    <Notification :data="notification"></Notification>
    <div
        v-show="!notification.show || notification.type !== 'success'"
        class="font-sans font-medium space-y-12"
    >
      <Form
          @submit="onSubmit"
          @invalid-submit="onInvalidSubmit"
          :validation-schema="schema"
          v-slot="{ values }"
          class="space-y-8"
      >
        <div class="input-group">
          <div>
            <h2 class="text-xl font-semibold leading-7 text-gray-900">Persönliche Daten</h2>

            <Grid class="mt-6">
              <UIInput
                  name="kontakt.vorname"
                  label="Vorname(n) - bitte alle Vornamen eintragen"
                  class="md:col-span-3"
                  required
              />
              <UIInput name="kontakt.nachname" label="Nachname" class="md:col-span-3" required/>
              <UIRadioGroup
                  name="kontakt.geschlecht"
                  label="Geschlecht"
                  :options="[
                  { label: 'weiblich', value: 'weiblich' },
                  { label: 'männlich', value: 'männlich' },
                  { label: 'divers', value: 'divers' }
                ]"
                  required
                  class="md:col-span-3"
              />
              <UIRadioGroup
                  name="kontakt.familienstand"
                  label="Familienstand"
                  :options="[
                  { label: 'ledig', value: 'ledig' },
                  { label: 'verheiratet', value: 'verheiratet' },
                  { label: 'geschieden', value: 'geschieden' },
                  { label: 'verwitwet', value: 'verwitwet' }
                ]"
                  required
                  class="md:col-span-3"
              />
              <UIDatePicker
                  name="kontakt.geburtsdatum"
                  label="Geburtsdatum"
                  description="Unser Freiwilligenprogramm unterliegt den weltwärts-Richtlinien, weshalb wir den
                  Freiwilligendienst ausschließlich für junge Menschen, die bei Ausreise zwischen 18
                  und 28 Jahren alt sind, anbieten können."
                  :minDate="DateTime.now().minus({ years: 30 }).toFormat('yyyy-MM-dd')"
                  :maxDate="DateTime.now().minus({ years: 18 }).toFormat('yyyy-MM-dd')"
                  class="md:col-span-3"
                  required
              />
              <UIInput
                  name="kontakt.geburtsort"
                  label="Geburtsort"
                  class="md:col-span-3"
                  required
              />
              <UIInput
                  name="kontakt.email"
                  label="E-Mail-Adresse"
                  type="email"
                  class="md:col-span-3"
                  required
              />
              <div class="hidden md:block md:col-span-3"></div>
              <UIInput name="kontakt.mobil" label="Mobil" class="md:col-span-3" required/>
              <UIInput name="kontakt.festnetz" label="Festnetz" class="md:col-span-3"/>
            </Grid>
          </div>

          <div>
            <h3 class="text-base font-semibold leading-none text-gray-900">Adresse</h3>
            <p class="mt-1 text-sm leading-6 text-gray-600">
              Aufgrund der weltwärts Richtlinien kannst du dich bewerben, wenn du die deutsche
              Staatsangehörigkeit oder ein dauerhaftes Aufenthaltsrecht in Deutschland hast.
            </p>
            <Grid class="mt-6">
              <UIInput
                  name="kontakt.adresse.strasse"
                  label="Straße und Hausnummer"
                  class="md:col-span-6"
                  required
              />
              <UIInput
                  name="kontakt.adresse.postleitzahl"
                  label="Postleitzahl"
                  class="md:col-span-3"
                  required
              />
              <UIInput name="kontakt.adresse.ort" label="Ort" class="md:col-span-3" required/>
              <UISelect
                  name="kontakt.adresse.land"
                  label="Land"
                  placeholder="Bitte wählen..."
                  :options="[{ label: 'Deutschland', value: 'Deutschland' }]"
                  class="sm:col-span-6"
                  required
              />
            </Grid>
          </div>

          <div>
            <h3 class="text-base font-semibold leading-none text-gray-900">Staatsangehörigkeit</h3>
            <Grid class="mt-6">
              <UIRadioGroup
                  name="kontakt.staatsangehoerigkeit"
                  label="Staatsangehörigkeit"
                  :options="[
                  { label: 'deutsch', value: 'deutsch' },
                  { label: 'sonstige', value: 'sonstige' }
                ]"
                  required
                  class="md:col-span-3"
              />
              <UIInput
                  v-if="values.kontakt?.staatsangehoerigkeit === 'sonstige'"
                  name="kontakt.staatsangehoerigkeit_nicht_deutsch"
                  label="Sonstige Staatsangehörigkeit"
                  description='Wenn du keine deutsche Staatsangehörigkeit hast, benötigst du ein
              "dauerhaftes Aufenthaltsrecht" in Deutschland, um laut der weltwärts-Richtlinien an
              unserem Freiwilligenprogramm teilzunehmen. Bitte erkundige dich, ob du es besitzt!'
                  class="md:col-span-3"
                  required
              />
            </Grid>
          </div>

          <div>
            <h3 class="text-base font-semibold leading-none text-gray-900">
              Religion / Konfession
            </h3>
            <Grid class="mt-6">
              <UIRadioGroup
                  name="kontakt.konfession"
                  label="Religion / Konfession"
                  :options="[
                  { label: 'katholisch', value: 'katholisch' },
                  { label: 'evangelisch', value: 'evangelisch' },
                  { label: 'sonstige', value: 'sonstige' }
                ]"
                  required
                  class="md:col-span-3"
              />
              <UISelect
                  v-if="values.kontakt?.konfession === 'katholisch'"
                  name="kontakt.heimatdioezese"
                  label="Heimatdiözese"
                  :options="heimatdiozese"
                  class="md:col-span-3"
                  required
              />
              <UIInput
                  v-if="values.kontakt?.konfession === 'sonstige'"
                  name="kontakt.konfession_sonstige"
                  label="Sonstige Konfession"
                  class="md:col-span-3"
                  required
              />
            </Grid>
          </div>
        </div>

        <div class="input-group">
          <div>
            <h2 class="text-xl font-semibold leading-7 text-gray-900">
              Schul- und Berufsausbildung, Studium
            </h2>
            <Grid class="mt-6">
              <!-- Grundschule -->
              <UIInput
                  name="karriere.grundschule.name"
                  label="Grundschule"
                  class="md:col-span-2"
                  required
              />
              <UISelect
                  name="karriere.grundschule.from"
                  label="von"
                  :options="
                  (() => {
                    return [
                      ...Interval.fromDateTimes(
                        DateTime.now().minus({ years: 24 }),
                        DateTime.now().minus({ years: 11 })
                      )
                        .splitBy({ year: 1 })
                        .map((d) => d.end)
                        .map((d) => {
                          if (d) {
                            return { label: d.year.toString(), value: d.year.toString() }
                          }
                          return { label: '', value: '' }
                        })
                    ]
                  })()
                "
                  class="md:col-span-2"
                  required
              />
              <UISelect
                  name="karriere.grundschule.to"
                  label="bis"
                  :options="
                  (() => {
                    return [
                      ...Interval.fromDateTimes(
                        DateTime.now().minus({ years: 21 }),
                        DateTime.now().minus({ years: 5 })
                      )
                        .splitBy({ year: 1 })
                        .map((d) => d.end)
                        .map((d) => {
                          if (d) {
                            return { label: d.year.toString(), value: d.year.toString() }
                          }
                          return { label: '', value: '' }
                        })
                    ]
                  })()
                "
                  class="md:col-span-2"
                  required
              />

              <!-- Weiterführende Schule -->
              <FieldArray name="karriere.weiterfuehrende_schule" v-slot="{ fields, push, remove }">
                <template v-for="(field, idx) in fields">
                  <UIInput
                      :name="`karriere.weiterfuehrende_schule[${idx}].name`"
                      label="Weiterführende Schule"
                      class="md:col-span-2"
                      required
                  />
                  <UISelect
                      :name="`karriere.weiterfuehrende_schule[${idx}].from`"
                      label="von"
                      placeholder="Jahr"
                      :options="
                      (() => {
                        return [
                          ...Interval.fromDateTimes(
                            DateTime.now().minus({ years: 21 }),
                            DateTime.now().minus({ years: 6 })
                          )
                            .splitBy({ year: 1 })
                            .map((d) => d.end)
                            .map((d) => {
                              if (d) {
                                return { label: d.year.toString(), value: d.year.toString() }
                              }
                              return { label: '', value: '' }
                            })
                        ]
                      })()
                    "
                      class="md:col-span-2"
                      required
                  />
                  <UISelect
                      :name="`karriere.weiterfuehrende_schule[${idx}].to`"
                      label="bis"
                      placeholder="Jahr"
                      :options="
                      (() => {
                        const moreThanOneSchool = fields.length > 1

                        return [
                          ...Interval.fromDateTimes(
                            DateTime.now().minus({ years: moreThanOneSchool ? 21 : 13 }),
                            DateTime.now()
                          )
                            .splitBy({ year: 1 })
                            .map((d) => d.end)
                            .map((d) => {
                              if (d) {
                                return { label: d.year.toString(), value: d.year.toString() }
                              }
                              return { label: '', value: '' }
                            })
                        ]
                      })()
                    "
                      class="md:col-span-2"
                      required
                  />
                  <div v-if="idx > 0" class="md:col-span-6 md:-mt-8">
                    <button
                        type="button"
                        class="flex items-center space-x-1 text-kmw-blue transition-colors hover:text-kmw-blue/60"
                        @click="remove(idx)"
                    >
                      <MinusIcon class="w-5 h-5"></MinusIcon>
                      <span>Entfernen</span>
                    </button>
                  </div>
                </template>
                <div class="md:col-span-6 md:-mt-4">
                  <button
                      type="button"
                      class="initial-click flex items-center space-x-1 text-kmw-blue transition-colors hover:text-kmw-blue/60"
                      @click="push({ name: '', from: '', to: '' })"
                  >
                    <PlusIcon class="w-5 h-5"></PlusIcon>
                    <span>Schulwechsel</span>
                  </button>
                </div>
              </FieldArray>

              <!-- Ausbildung -->
              <UIInput
                  name="karriere.ausbildung.name"
                  label="Berufsausbildung"
                  class="md:col-span-2"
              />
              <UISelect
                  name="karriere.ausbildung.from"
                  label="von"
                  placeholder="Jahr"
                  :options="
                  (() => {
                    return [
                      ...Interval.fromDateTimes(
                        DateTime.now().minus({ years: 18 }),
                        DateTime.now().minus({ years: 1 })
                      )
                        .splitBy({ year: 1 })
                        .map((d) => d.end)
                        .map((d) => {
                          if (d) {
                            return { label: d.year.toString(), value: d.year.toString() }
                          }
                          return { label: '', value: '' }
                        })
                    ]
                  })()
                "
                  class="md:col-span-2"
              />
              <UISelect
                  name="karriere.ausbildung.to"
                  label="bis"
                  placeholder="Jahr"
                  :options="
                  (() => {
                    return [
                      ...Interval.fromDateTimes(DateTime.now().minus({ years: 16 }), DateTime.now())
                        .splitBy({ year: 1 })
                        .map((d) => d.end)
                        .map((d) => {
                          if (d) {
                            return { label: d.year.toString(), value: d.year.toString() }
                          }
                          return { label: '', value: '' }
                        })
                    ]
                  })()
                "
                  class="md:col-span-2"
              />

              <!-- Studium -->
              <FieldArray name="karriere.studium" v-slot="{ fields, push, remove }">
                <template v-for="(field, idx) in fields">
                  <UIInput :name="`karriere.studium[${idx}].name`" label="Studium" class="md:col-span-2"/>
                  <UISelect
                      :name="`karriere.studium[${idx}].from`"
                      label="von"
                      placeholder="Jahr"
                      :options="
                  (() => {
                    return [
                      ...Interval.fromDateTimes(
                        DateTime.now().minus({ years: 18 }),
                        DateTime.now().minus({ years: 1 })
                      )
                        .splitBy({ year: 1 })
                        .map((d) => d.end)
                        .map((d) => {
                          if (d) {
                            return { label: d.year.toString(), value: d.year.toString() }
                          }
                          return { label: '', value: '' }
                        })
                    ]
                  })()
                "
                      class="md:col-span-2"
                  />
                  <UISelect
                      :name="`karriere.studium[${idx}].to`"
                      label="bis"
                      placeholder="Jahr"
                      :options="
                  (() => {
                    return [
                      ...Interval.fromDateTimes(DateTime.now().minus({ years: 16 }), DateTime.now())
                        .splitBy({ year: 1 })
                        .map((d) => d.end)
                        .map((d) => {
                          if (d) {
                            return { label: d.year.toString(), value: d.year.toString() }
                          }
                          return { label: '', value: '' }
                        })
                    ]
                  })()
                "
                      class="md:col-span-2"
                  />
                  <div v-if="idx > 0" class="md:col-span-6 md:-mt-8">
                    <button
                        type="button"
                        class="flex items-center space-x-1 text-kmw-blue transition-colors hover:text-kmw-blue/60"
                        @click="remove(idx)"
                    >
                      <MinusIcon class="w-5 h-5"></MinusIcon>
                      <span>Entfernen</span>
                    </button>
                  </div>
                </template>
                <div class="md:col-span-6 md:-mt-4">
                  <button
                      type="button"
                      class="initial-click flex items-center space-x-1 text-kmw-blue transition-colors hover:text-kmw-blue/60"
                      @click="push({ name: '', from: '', to: '' })"
                  >
                    <PlusIcon class="w-5 h-5"></PlusIcon>
                    <span>Weiteres Studium</span>
                  </button>
                </div>
              </FieldArray>

              <!-- Aktuelle Tätigkeit -->
              <UIInput
                  name="karriere.zurzeit_taetig_als"
                  label="Zurzeit tätig als"
                  class="md:col-span-4"
                  required
              />
            </Grid>
          </div>
        </div>

        <div class="input-group">
          <div>
            <h2 class="text-xl font-semibold leading-7 text-gray-900">Engagement</h2>
            <Grid class="mt-6">
              <UIRadioGroup
                  name="engagement.engagiert.status"
                  label="Bist oder warst du sozial, politisch, sportlich, kulturell oder kirchlich engagiert?"
                  :options="[
                  { label: 'ja', value: 'ja' },
                  { label: 'nein', value: 'nein' }
                ]"
                  required
                  class="md:col-span-4"
              ></UIRadioGroup>

              <!-- Engagements -->
              <div v-show="values.engagement?.engagiert?.status === 'ja'" class="md:col-span-6">
                <Grid>
                  <FieldArray name="engagement.engagiert.items" v-slot="{ fields, push, remove }">
                    <template v-for="(field, idx) in fields">
                      <UIInput
                          :name="`engagement.engagiert.items[${idx}].als`"
                          label="als"
                          class="md:col-start-1 md:col-span-2"
                      />
                      <UIInput
                          :name="`engagement.engagiert.items[${idx}].zeitraum`"
                          label="Zeitraum"
                          class="md:col-span-2"
                      />
                      <div v-if="idx > 0" class="md:col-span-2 md:self-center">
                        <button
                            type="button"
                            class="flex items-center space-x-1 text-kmw-blue transition-colors hover:text-kmw-blue/60"
                            @click="remove(idx)"
                        >
                          <MinusIcon class="w-5 h-5"></MinusIcon>
                          <span>Entfernen</span>
                        </button>
                      </div>
                    </template>
                    <div class="md:col-span-6 md:-mt-4">
                      <button
                          type="button"
                          class="initial-click flex items-center space-x-1 text-kmw-blue transition-colors hover:text-kmw-blue/60"
                          @click="push({ als: '', zeitraum: '' })"
                      >
                        <PlusIcon class="w-5 h-5"></PlusIcon>
                        <span>weiteres Engagement</span>
                      </button>
                    </div>
                  </FieldArray>
                </Grid>
              </div>

              <UIRadioGroup
                  name="engagement.arbeitserfahrung.status"
                  label="Hast du schon Erfahrungen in der Arbeitswelt gesammelt?"
                  description="Hierzu zählen zum Beispiel Schul-, Betriebs-, Sozialpraktika, Nebenjobs oder Aushilfstätigkeiten."
                  :options="[
                  { label: 'ja', value: 'ja' },
                  { label: 'nein', value: 'nein' }
                ]"
                  required
                  class="md:col-span-4"
              ></UIRadioGroup>

              <!-- Arbeitserfahrungen -->
              <div
                  v-show="values.engagement?.arbeitserfahrung?.status === 'ja'"
                  class="md:col-span-6"
              >
                <Grid>
                  <FieldArray
                      name="engagement.arbeitserfahrung.items"
                      v-slot="{ fields, push, remove }"
                  >
                    <template v-for="(field, idx) in fields">
                      <UIInput
                          :name="`engagement.arbeitserfahrung.items[${idx}].zeitraum`"
                          label="Zeitraum"
                          class="md:col-span-3"
                      />
                      <UITextArea
                          :name="`engagement.arbeitserfahrung.items[${idx}].erlaeuterung`"
                          label="Erläuterung"
                          class="md:col-span-3"
                      />
                      <div v-if="idx > 0" class="md:col-span-6 md:self-center">
                        <button
                            type="button"
                            class="flex items-center space-x-1 text-kmw-blue transition-colors hover:text-kmw-blue/60"
                            @click="remove(idx)"
                        >
                          <MinusIcon class="w-5 h-5"></MinusIcon>
                          <span>Entfernen</span>
                        </button>
                      </div>
                    </template>
                    <div class="md:col-span-6 md:-mt-4">
                      <button
                          type="button"
                          class="initial-click flex items-center space-x-1 text-kmw-blue transition-colors hover:text-kmw-blue/60"
                          @click="push({ zeitraum: '', erlaeuterung: '' })"
                      >
                        <PlusIcon class="w-5 h-5"></PlusIcon>
                        <span>weitere Arbeitserfahrung</span>
                      </button>
                    </div>
                  </FieldArray>
                </Grid>
              </div>

              <UIRadioGroup
                  name="engagement.auslandserfahrungen.status"
                  label="Hast du schon Erfahrungen im Ausland gemacht?"
                  description="Hierzu zählen beispielsweise Austauschdienste, Studienfahrten oder auch Urlaube."
                  :options="[
                  { label: 'ja', value: 'ja' },
                  { label: 'nein', value: 'nein' }
                ]"
                  required
                  class="md:col-span-4"
              ></UIRadioGroup>

              <!-- Auslandserfahrungen -->
              <div
                  v-show="values.engagement?.auslandserfahrungen?.status === 'ja'"
                  class="md:col-span-6"
              >
                <Grid>
                  <FieldArray
                      name="engagement.auslandserfahrungen.items"
                      v-slot="{ fields, push, remove }"
                  >
                    <template v-for="(field, idx) in fields">
                      <UIInput
                          :name="`engagement.auslandserfahrungen.items[${idx}].zeitraum`"
                          label="Zeitraum"
                          class="md:col-span-3"
                      />
                      <UITextArea
                          :name="`engagement.auslandserfahrungen.items[${idx}].erlaeuterung`"
                          label="Erläuterung"
                          class="md:col-span-3"
                      />
                      <div v-if="idx > 0" class="md:col-span-6 md:self-center">
                        <button
                            type="button"
                            class="flex items-center space-x-1 text-kmw-blue transition-colors hover:text-kmw-blue/60"
                            @click="remove(idx)"
                        >
                          <MinusIcon class="w-5 h-5"></MinusIcon>
                          <span>Entfernen</span>
                        </button>
                      </div>
                    </template>
                    <div class="md:col-span-6 md:-mt-4">
                      <button
                          type="button"
                          class="initial-click flex items-center space-x-1 text-kmw-blue transition-colors hover:text-kmw-blue/60"
                          @click="push({ zeitraum: '', erlaeuterung: '' })"
                      >
                        <PlusIcon class="w-5 h-5"></PlusIcon>
                        <span>weitere Auslandserfahrung</span>
                      </button>
                    </div>
                  </FieldArray>
                </Grid>
              </div>
            </Grid>
          </div>
        </div>

        <div class="input-group">
          <div>
            <h2 class="text-xl font-semibold leading-7 text-gray-900">Sprachkenntnisse</h2>
            <Grid class="mt-6">
              <UIRadioGroup
                  name="sprachen.englisch"
                  label="Englisch"
                  :options="[
                  { label: 'keine', value: 'keine' },
                  { label: 'Grundkenntnisse', value: 'Grundkenntnisse' },
                  { label: 'gut', value: 'gut' },
                  { label: 'sehr gut', value: 'sehr gut' }
                ]"
                  required
                  class="md:col-span-2"
              ></UIRadioGroup>
              <UIRadioGroup
                  name="sprachen.spanisch"
                  label="Spanisch"
                  :options="[
                  { label: 'keine', value: 'keine' },
                  { label: 'Grundkenntnisse', value: 'Grundkenntnisse' },
                  { label: 'gut', value: 'gut' },
                  { label: 'sehr gut', value: 'sehr gut' }
                ]"
                  required
                  class="md:col-span-2"
              ></UIRadioGroup>
              <UIRadioGroup
                  name="sprachen.franzoesisch"
                  label="Französisch"
                  :options="[
                  { label: 'keine', value: 'keine' },
                  { label: 'Grundkenntnisse', value: 'Grundkenntnisse' },
                  { label: 'gut', value: 'gut' },
                  { label: 'sehr gut', value: 'sehr gut' }
                ]"
                  required
                  class="md:col-span-2"
              ></UIRadioGroup>
              <UICheckboxGroup
                  name="sprachen.sonstige"
                  label="Weitere"
                  :options="[{ label: 'Sonstiges', value: 'Sonstiges' }]"
                  class="md:col-span-3"
              />
              <UITextArea
                  v-if="values?.sprachen?.sonstige == 'Sonstiges'"
                  name="sprachen.sonstiges"
                  label="Welche Sprachen?"
                  class="md:col-span-6"
              />
            </Grid>
          </div>
        </div>

        <div class="input-group">
          <div>
            <h2 class="text-xl font-semibold leading-7 text-gray-900">Einsatzplatz</h2>
            <p class="mt-1 leading-6 text-gray-600">
              Du kannst dich nicht auf einen konkreten Einsatzplatz bewerben, dennoch versuchen wir
              natürlich, deine Wünsche zu berücksichtigen. Bitte gib 5 Einsatzstellen an, die dich
              am meisten interessieren.
            </p>
            <p
                name="einsatzplan.orte"
                class="text-sm text-red-600"
                :class="{ 'mt-2': einsatzplanError }"
                v-text="einsatzplanError"
            ></p>
          </div>

          <div>
            <h3 class="text-base font-semibold leading-none text-gray-900">Afrika</h3>
            <Grid class="mt-6">
              <UICheckboxGroup
                  name="einsatzplan.orte.afrika.malawi"
                  label="Malawi"
                  :options="[
                  { label: 'Benga', value: 'Benga' },
                  { label: 'Tikondane', value: 'Tikondane' }
                ]"
                  class="md:col-span-3"
              />
              <UICheckboxGroup
                  name="einsatzplan.orte.afrika.suedafrika"
                  label="Südafrika"
                  :options="[
                  { label: 'George', value: 'George' },
                  { label: 'Johannesburg', value: 'Johannesburg' }
                ]"
                  class="md:col-span-3"
              />
              <UICheckboxGroup
                  name="einsatzplan.orte.afrika.uganda"
                  label="Uganda"
                  :options="[
                  { label: 'Masaka', value: 'Masaka' },
                  { label: 'Mityana', value: 'Mityana' }
                ]"
                  class="md:col-span-3"
              />
            </Grid>
          </div>

          <div>
            <h3 class="text-base font-semibold leading-none text-gray-900">Asien</h3>
            <Grid class="mt-6">
              <UICheckboxGroup
                  name="einsatzplan.orte.asien.kambodscha"
                  label="Kambodscha"
                  :options="[
                  { label: 'Poipet', value: 'Poipet' },
                  { label: 'Kep', value: 'Kep' }
                ]"
                  class="md:col-span-3"
              />
              <UICheckboxGroup
                  name="einsatzplan.orte.asien.philippinen"
                  label="Philippinen"
                  :options="[{ label: 'Cebu City', value: 'Cebu City' }]"
                  class="md:col-span-3"
              />
            </Grid>
          </div>

          <div>
            <h3 class="text-base font-semibold leading-none text-gray-900">Lateinamerika</h3>
            <Grid class="mt-6">
              <UICheckboxGroup
                  name="einsatzplan.orte.lateinamerika.ecuador"
                  label="Ecuador"
                  :options="[
                  { label: 'Quito', value: 'Quito' }
                ]"
                  class="md:col-span-3"
              />
              <UICheckboxGroup
                  name="einsatzplan.orte.lateinamerika.kolumbien"
                  label="Kolumbien"
                  :options="[
                  { label: 'Medellín', value: 'Medellín' }
                ]"
                  class="md:col-span-3"
              />
              <UICheckboxGroup
                  name="einsatzplan.orte.lateinamerika.mexiko"
                  label="Mexiko"
                  :options="[
                  { label: 'Guadalajara', value: 'Guadalajara' },
                  { label: 'Puebla', value: 'Puebla' }
                ]"
                  class="md:col-span-3"
              />
              <UICheckboxGroup
                  name="einsatzplan.orte.lateinamerika.peru"
                  label="Peru"
                  :options="[
                  { label: 'Cajamarca', value: 'Cajamarca' },
                  { label: 'Lima (Cieneguilla)', value: 'Lima (Cieneguilla)' },
                  { label: 'Lima (San Juan de Lurigancho)', value: 'Lima (San Juan de Lurigancho)' },
                  { label: 'Urubamba', value: 'Urubamba' }
                ]"
                  class="md:col-span-3"
              />
              <UICheckboxGroup
                  name="einsatzplan.orte.lateinamerika.dominikanische_republik"
                  label="Dominikanische Republik"
                  :options="[{ label: 'Bonao', value: 'Bonao' }]"
                  class="md:col-span-3"
              />
            </Grid>
          </div>

          <div>
            <Grid class="mt-6">
              <UIRadioGroup
                  name="einsatzplan.interesse"
                  label="Gibt es einen oder mehrere Einsatzplätze, die für dich nicht in Frage kommen?"
                  :options="[
                  { label: 'ja', value: 'ja' },
                  { label: 'nein', value: 'nein' }
                ]"
                  required
                  class="md:col-span-6"
              ></UIRadioGroup>
              <template v-if="values.einsatzplan?.interesse === 'ja'">
                <UITextArea
                    name="einsatzplan.erlaeuterung"
                    label="Bitte nenne diese und begründe deine Antwort."
                    class="md:col-span-4"
                />
              </template>
            </Grid>
          </div>
        </div>

        <div class="input-group">
          <div>
            <h2 class="text-xl font-semibold leading-7 text-gray-900">
              Wie bist du auf unseren Freiwilligendienst aufmerksam geworden?
            </h2>
            <Grid class="mt-6">
              <UICheckboxGroup
                  name="aufmerksam.durch"
                  label="Durch"
                  :options="[
                  { label: 'weltwärts', value: 'weltwaerts' },
                  {
                    value: 'sternsinger_internet',
                    label: 'Internetauftritt vom Kindermissionswerks ,Die Sternsinger’ e.V.'
                  },
                  {
                    value: 'sternsinger_veranstaltung',
                    label: 'Veranstaltung vom Kindermissionswerks ,Die Sternsinger’ e.V.'
                  },
                  {
                    value: 'info_veranstaltung',
                    label:
                      'Infoveranstaltung des Freiwilligendienstes des Kindermissionswerks ,Die Sternsinger’ e.V.'
                  },
                  {
                    value: 'info_hochschule',
                    label: 'Über meine Universität/Hochschule'
                  },
                  {
                    value: 'info_ausbildung',
                    label: 'Über den Ausbildungsbetrieb'
                  },
                  {
                    value: 'info_schule',
                    label: 'Über meine Schule'
                  },
                  { value: 'empfehlung', label: 'Persönliche Empfehlung' },
                  { value: 'social_media', label: 'Social Media' },
                  { value: 'sonstiges', label: 'Sonstiges' }
                ]"
                  required
                  class="md:col-span-3"
              />
              <UITextArea
                  v-if="
                  values.aufmerksam?.durch?.includes &&
                  values.aufmerksam?.durch?.includes('sonstiges')
                "
                  name="aufmerksam.sonstiges"
                  label="Welche Veranstaltung?"
                  class="md:col-span-6"
              />
            </Grid>
          </div>
        </div>

        <div class="input-group">
          <div>
            <h2 class="text-xl font-semibold leading-7 text-gray-900">Upload</h2>
            <Grid class="mt-6">
              <UIFileInput
                  name="uploads.bewerbungsfoto"
                  label="Bewerbungsfoto"
                  description="Bitte lade ein Bewerbungsfoto von dir hoch. Format JPG oder PNG (max. 10 MB)."
                  class="md:col-span-full"
                  accept="image/png,image/jpeg"
                  required
              />
              <UIFileInput
                  name="uploads.motivation"
                  label="Motivationsschreiben"
                  description="Hier kannst du dein Motivationsschreiben hochladen. Bitte als PDF (max. 10 MB)."
                  class="md:col-span-full"
                  accept="application/pdf"
                  required
              />
              <UIFileInput
                  name="uploads.zeugnis"
                  label="Letztes Schul-, Arbeits- oder Studiumszeugnis"
                  description="Dein letztes Schul-, Arbeits- oder Studiumszeugnis. JPG, PNG oder PDF (max. 10 MB)."
                  class="md:col-span-full"
                  accept="application/pdf,image/png,image/jpeg"
                  required
              />
              <UIFileInput
                  name="uploads.nachweise"
                  label="Weitere relevante Nachweise und Empfehlungsschreiben (JPG, PNG oder PDF, max. 10 MB)"
                  class="md:col-span-full"
                  accept="application/pdf,image/png,image/jpeg"
                  multiple
              />
            </Grid>
          </div>
        </div>

        <div class="input-group">
          <div>
            <h2 class="text-xl font-semibold leading-7 text-gray-900">Bestätigung</h2>
            <Grid class="mt-6">
              <UICheckboxGroup
                  name="bestaetigung"
                  label="Bitte bestätigen"
                  :options="[
                  {
                    label: 'Ich nehme an den verpflichtenden Seminare vollständig teil!',
                    value: 'Seminarteilnahme'
                  },
                  {
                    label:
                      'Ein entwicklungspolitischer Freiwilligendienst stellt hohe Anforderungen an die körperliche und psychische Belastbarkeit. Ich bin damit einverstanden, dass das Kindermissionswerk ,Die Sternsinger’ e.V. in der Vorbereitungszeit ggf. ärztliche oder psychologische Untersuchungen durchführen lässt, um meine Eignung für diesen Dienst zu überprüfen. In diesem Zusammenhang entbinde ich die behandelnden Ärztinnen und Ärzte bzw. Psychologinnen und Psychologen von ihrer Schweigepflicht. Mir ist bewusst und ich erkläre mich damit einverstanden, dass das Ergebnis dieser Untersuchungen Auswirkungen für die Durchführung meines Freiwilligendienstes hat.',
                    value: 'Untersuchungen'
                  },
                  {
                    label: 'Datenschutz',
                    value: 'Datenschutzerklärung',
                    slotName: 'privacy'
                  }
                ]"
                  required
                  class="md:col-span-full"
              >
                <template v-slot:privacy>
                  Ich bin mit dem Umgang meiner Daten laut der
                  <a
                      target="_blank"
                      class="hover:underline"
                      href="https://mein-eine-welt-jahr.de/datenschutz"
                  >Datenschutzerklärung</a
                  >
                  auf www.mein-eine-welt-jahr.de einverstanden.
                </template>
              </UICheckboxGroup>
            </Grid>
          </div>
        </div>

        <div class="my-8">
          <h2 class="text-xl font-semibold leading-7 text-[#339966]">Fertig</h2>
          <div class="mt-2 space-y-1">
            <p>
              Deine Bewerbung kann nicht abgeschickt werden, wenn Pflichtfelder nicht ausgefüllt
              sind oder Dokumente fehlen!
            </p>
            <p>
              Nachdem du deine Bewerbung über den Button "senden" abgeschickt hast, erhältst du eine
              automatische Bestätigung per E-Mail, dass deine Bewerbung bei uns eingegangen ist.
              Wenn du keine Bestätigungsmail bekommen hast, kontrolliere bitte Deinen Spamordner und
              setze dich bitte direkt mit uns in Verbindung!
            </p>
          </div>
        </div>

        <div class="my-8">
          <button type="submit" class="flex button button--primary" :disabled="loading">
            <svg
                v-if="loading"
                aria-hidden="true"
                role="status"
                class="animate-spin h-5 w-5 mr-3"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#fff"
              />
              <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
              />
            </svg>
            Senden
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>

<style lang="postcss">
@import url('../assets/css/main.css');
</style>
