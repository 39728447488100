export const heimatdiozese = [
  { label: 'Aachen', value: 'Aachen' },
  { label: 'Augsburg', value: 'Augsburg' },
  { label: 'Bamberg', value: 'Bamberg' },
  { label: 'Berlin', value: 'Berlin' },
  { label: 'Dresden-Meißen', value: 'Dresden-Meißen' },
  { label: 'Eichstätt', value: 'Eichstätt' },
  { label: 'Erfurt', value: 'Erfurt' },
  { label: 'Essen', value: 'Essen' },
  { label: 'Freiburg', value: 'Freiburg' },
  { label: 'Fulda', value: 'Fulda' },
  { label: 'Görlitz', value: 'Görlitz' },
  { label: 'Hamburg', value: 'Hamburg' },
  { label: 'Hildesheim', value: 'Hildesheim' },
  { label: 'Köln', value: 'Köln' },
  { label: 'Limburg', value: 'Limburg' },
  { label: 'Magdeburg', value: 'Magdeburg' },
  { label: 'Mainz', value: 'Mainz' },
  { label: 'München und Freising', value: 'München und Freising' },
  { label: 'Münster', value: 'Münster' },
  { label: 'Osnabrück', value: 'Osnabrück' },
  { label: 'Paderborn', value: 'Paderborn' },
  { label: 'Passau', value: 'Passau' },
  { label: 'Regensburg', value: 'Regensburg' },
  { label: 'Rottenburg-Stuttgart', value: 'Rottenburg-Stuttgart' },
  { label: 'Speyer', value: 'Speyer' },
  { label: 'Trier', value: 'Trier' },
  { label: 'Würzburg', value: 'Würzburg' },
  { label: 'Andere/Keine', value: 'Andere/Keine' }
]
