<script setup lang="ts">
import { useField } from 'vee-validate'

const props = defineProps<{
  name: string
  label: string
  description?: string
  type?: string
  rules?: any
  required?: boolean
}>()

const { value, errorMessage } = useField(props.name, props.rules)
</script>

<template>
  <div>
    <label :for="name" class="block text-sm font-medium leading-6 text-gray-900">
      <div>{{ label }}<span v-if="required" class="pl-1 text-kmw-blue font-normal">(Pflichtfeld)</span></div>
      <p v-if="description" class="mt-px text-sm leading-6 text-gray-600">
        {{ description }}
      </p></label
    >
    <div class="mt-1">
      <input
        :id="name"
        :name="name"
        v-model="value"
        :type="type ?? 'text'"
        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-kmw-blue sm:text-sm sm:leading-6"
      />
    </div>
    <p class="mt-px text-sm text-red-600" v-show="errorMessage">
      {{ errorMessage }}
    </p>
  </div>
</template>
