<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/vue'
import { ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { useField } from 'vee-validate'

const props = defineProps<{
  name: string
  label: string
  description?: string
  options: {
    label: string
    value: string
    disabled?: boolean
    default?: boolean
  }[]
  required?: boolean
}>()

const selectedValue = ref<{
  label: string
  value: string
  disabled?: boolean
  default?: boolean
} | null>(null)
const { value: hiddenValue, errorMessage } = useField(props.name)

onMounted(() => {
  selectedValue.value = props.options.find((opt) => opt.default) ?? null
})

watch(selectedValue, () => {
  hiddenValue.value = selectedValue.value?.value
})
</script>

<template>
  <div>
    <label :for="name" class="block text-sm font-medium leading-6 text-gray-900">
      <div>
        {{ label }}<span v-if="required" class="pl-1 text-kmw-blue font-normal">(Pflichtfeld)</span>
      </div>
      <p v-if="description" class="mt-px text-sm leading-6 text-gray-600">
        {{ description }}
      </p></label
    >
    <input type="hidden" :id="name" :name="name" class="hidden" :value="hiddenValue" />
    <Listbox v-model="selectedValue">
      <div class="relative mt-1">
        <ListboxButton
          class="relative w-full cursor-default rounded-md bg-white shadow-sm border-0 py-2 pl-3 pr-10 text-left text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-inset focus-visible:ring-kmw-blue sm:text-sm sm:leading-6"
        >
          <span class="block truncate empty:after:content-['.'] empty:after:invisible">{{
            selectedValue?.label
          }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 z-10 focus:outline-none sm:text-sm"
          >
            <ListboxOption
              v-slot="{ active, selected }"
              v-for="option in options"
              :key="option.label"
              :value="option"
              :disabled="option.disabled"
              as="template"
            >
              <li
                :class="[
                  active && !option.disabled ? 'bg-kmw-blue/10 text-kmw-blue' : '',
                  option.disabled ? 'text-gray-300' : 'text-gray-900',
                  'relative cursor-default select-none py-2 px-4'
                ]"
              >
                <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">{{
                  option.label
                }}</span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
    <p class="mt-1 text-sm text-red-600" v-show="errorMessage">
      {{ errorMessage }}
    </p>
  </div>
</template>
